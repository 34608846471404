section.contacts-partners {
	background: #DBDBDB;
	padding-top: 48px;
	padding-bottom: 54px;

	.form__success {
		background: #DBDBDB; }

	.container {
		display: flex;

		@include mobile {
			flex-direction: column; } } }

.contacts-partners__intro {
	width: 50%;
	padding-right: 22px;

	@include mobile {
		display: none; } }

.contacts-partners__info {
	width: 50%;
	padding-left: 22px;

	@include mobile {
		width: 100%;
		padding-left: 0; } }

.contacts-partners-intro__title {
	font-weight: 500;
	font-size: 24px;
	line-height: 120%;
	margin-bottom: 20px;

	@include tablet {
		font-size: 24px;
		margin-bottom: 20px; } }

.contacts-partners__title {
	font-weight: 500;
	font-size: 32px;
	line-height: 120%;
	margin-bottom: 24px;

	@include tablet {
		font-size: 24px; } }

.contacts-partners__desc {
	font-size: 16px;
	line-height: 100%;
	color: #3E3F42;
	margin-top: 16px;

	svg {
		width: 12px;
		height: 12px;
		stroke: $orange; } }

.contacts-partners__form {
	max-width: 420px;

	@include tablet {
		max-width: 100%; } }
