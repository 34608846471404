footer {
	flex: 0 0 auto;
	background: #121216;
	color: $white;
	padding-top: 66px;
	padding-bottom: 142px;

	.container {
		display: flex; }

	.footer__logo {
		display: flex;
		flex-direction: column;
		margin-right: 30px;

		.button {
			width: 210px;
			margin-bottom: 16px;

			&:hover {
				color: #fff; } } }

	.footer-logo__link {
		display: flex;
		flex-direction: column;
		width: 130px;
		color: $black;
		text-decoration: none;
		margin-bottom: 36px;

		svg {
			width: 192px;
			height: 50px;
			fill: $white; } }

	.footer__contacts {
		margin-top: 20px;
		font-size: 20px;
		line-height: 100%; }

	.footer-phone__link {
		color: #ffffff;
		text-decoration: none; }

	.footer__copy {
		margin-top: 10px; }

	.footer__nav {
		width: 357px;

		&.nav--main {
			margin-left: auto; }

		&.nav--catalog {} }

	.footer-nav__list {
		list-style: none;
		padding: 0;
		margin: 0; }

	.footer-nav__item + .footer-nav__item {
		margin-top: 14px; }

	.footer-nav__link {
		color: #fff;
		font-size: 16px;
		line-height: 100%;
		text-decoration: none;
		text-transform: lowercase; } }
