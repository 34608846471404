.category__filter {

	.category-filter__wrap {
		display: flex;
		justify-content: center;
		margin-bottom: 32px;
		flex-wrap: wrap; }

	label.category-filter__label {
		display: flex;
		margin: 0 10px 8px 10px;
		cursor: pointer;

		input {
			position: absolute;
			opacity: 0;
			visibility: hidden;
			width: 0;

			&:checked {

				& + .category-filter__value {
					background: $orange;
					border-color: $orange;
					color: $white; } } } }

	.category-filter__value {
		white-space: nowrap;
		background: #FFFFFF;
		border: 1px solid #3E3F42;
		border-radius: 100px;
		height: 33px;
		padding-left: 20px;
		padding-right: 20px;
		display: flex;
		align-items: center; } }
