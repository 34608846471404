div.page-content {

	&.page-404 {

		main {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center; }

		h1 {
			font-weight: 500;
			font-size: 64px;
			line-height: 120%;
			color: #121216;
			margin-bottom: 12px; }

		.error404__title {
			font-weight: 500;
			font-size: 32px;
			line-height: 120%;
			color: #121216;
			margin-bottom: 32px; }

		.error__button {
			display: flex;

			.button {
				width: 210px;

				& + .button {
					margin-left: 60px; } } } } }
