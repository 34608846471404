// Colors
$white: #ffffff;
$black: #121216;
$orange: #E5372E;

// Sizes
$base-font-size: 15px;

// Family
$base-font-family: 'Inter';

// transition-duration
$trds: 0.5s;

$breakpoints: (
  "phone"       : 400px,
  "phone-wide"  : 480px,
  "phablet"     : 560px,
  "tablet-small": 640px,
  "tablet"      : 768px,
  "tablet-wide" : 1024px,
  "desktop"     : 1248px,
  "desktop-wide": 1440px
);

$content-size: 1230px;

// Fonts