header {
	flex: 0 0 auto;
	background: #FFFFFF;
	height: 97px;
	border-bottom: 2px solid #DBDBDB;
	padding-top: 5px;
	padding-bottom: 5px;
	box-shadow: 0 0 20px rgba(0,0,0,.05);

	.container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 100%; }

	.header__phone {
		width: 200px;
		position: relative; }

	.header-phone__value {
		display: flex;
		align-items: center;
		margin-bottom: 12px;

		svg {
			fill: #74787D;
			stroke: #74787D;
			width: 16px;
			height: 14px; } }

	.header-phone__link {
		text-decoration: none;
		font-size: 18px;
		margin-left: 9px;
		line-height: 100%;
		color: #3E3F42;
		transition-duration: $trds;

		&:hover {
			color: $orange; } }

	.header-logo__link {
		display: flex;
		flex-direction: column;
		text-decoration: none;

		svg {
			width: 192px;
			height: 50px;
			fill: $orange; } }

	.header__search {
		display: flex;
		align-items: center;
		width: 200px; }

	.header-search__form {
		margin-top: 4px; }

	.header-search__open {
		margin-right: 25px;

		@include tablet {
			margin-right: 0; }

		svg {
			width: 24px;
			height: 24px;
			stroke: #74787D; } } }

.nav {
	height: 61px;
	background: #fff;

	.container {
		display: flex;
		align-content: center;
		justify-content: center;
		height: 100%; }

	.nav__list {
		display: flex;
		align-items: center; }

	.nav__item {
		padding: 0 16px;

		&:last-child {
			margin-right: 0; } }

	.nav__link {
		font-size: 16px;
		color: #000;
		text-decoration: none;
		transition-duration: $trds;
		display: flex;
		align-items: center;
		text-align: center;

		&:hover {
			color: $orange; } } }

a.open__nav {
	text-transform: uppercase;
	color: #ffffff;
	font-weight: 700;
	text-align: center;
	text-decoration: none;
	display: none;
	min-width: 28px;
	width: 28px;
	height: 21px;
	position: relative;
	transform: rotate(0deg);
	transition: .5s ease-in-out;
	cursor: pointer;

	&.open {

		span {

			&:nth-child(1) {
				top: 18px;
				width: 0%;
				left: 50%; }

			&:nth-child(2) {
				transform: rotate(45deg); }

			&:nth-child(3) {
				transform: rotate(-45deg); }

			&:nth-child(4) {
				top: 18px;
				width: 0%;
				left: 50%; } } }

	span {
		display: block;
		position: absolute;
		height: 2px;
		width: 100%;
		background: #121216;
		border-radius: 9px;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: .25s ease-in-out;

		&:nth-child(1) {
			top: 0px; }

		&:nth-child(2), &:nth-child(3) {
			top: 9px; }

		&:nth-child(4) {
			top: 18px; } } }
