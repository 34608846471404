section.category {

	&.category--item {

		.category__intro {
			margin-top: 8px;
			font-weight: 400;
			font-size: 16px;
			line-height: 120%; }

		.category__value {
			position: absolute;
			left: 24px;
			top: 12px;
			color: #3E3F42;
			z-index: 5; } }

	.category__row {
		margin-left: -12px;
		margin-right: -12px;
		display: flex;
		flex-wrap: wrap; }

	.category__item {
		padding-left: 12px;
		padding-right: 12px;
		width: 25%;
		margin-bottom: 24px;

		@include laptop {
			width: 33.33%; } }

	.category__item-inner {
		display: block;
		text-decoration: none;
		background: #fff;
		height: 100%;
		overflow: hidden;
		position: relative;

		&:hover {

			.category__img {
				transform: scale(1.1); } } }

	.category__img {
		display: block;
		width: 100%;
		transition-duration: $trds; }

	.category__title {
		font-weight: 500;
		font-size: 24px;
		line-height: 120%;
		color: #121216;
		padding: 24px;
		padding-top: 12px; } }

.category__search {
	display: flex;
	justify-content: space-between;

	@include mobile {
		flex-direction: column; }

	button {
		display: none; }

	input {
		height: 40px;
		width: 100%;
		border: 1px solid #999999;
		border-radius: 25px;
		padding-left: 45px;
		padding-right: 12px;
		font-family: $base-font-family;
		background-image: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.375 16.625C7.50901 16.625 4.375 13.491 4.375 9.625C4.375 5.75901 7.50901 2.625 11.375 2.625C15.241 2.625 18.375 5.75901 18.375 9.625C18.375 13.491 15.241 16.625 11.375 16.625Z' stroke='%23121216' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M2.62539 18.375L6.43164 14.5687' stroke='%23121216' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
		background-position: 15px 50%;
		background-repeat: no-repeat; } }

.ui-widget.ui-widget-content {
	border: none !important;
	border-radius:  12px;
	font-family: $base-font-family; }

.ui-menu .ui-menu-divider {
	display: none !important; }

.ui-menu .ui-menu-item {
	padding-top: 5px;
	padding-left: 15px;
	padding-right: 15px;
	padding-bottom: 5px;
	color: #999999; }
