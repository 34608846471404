.news__list {
	display: flex;
	margin-left: -12px;
	margin-right: -12px;

	@include mobile {
		flex-wrap: wrap; } }

.news__big {
	width: 50%;

	@include tablet {
		width: 33.33%; }

	@include mobile {
		width: 100%; } }

.news__small {
	width: 50%;
	display: flex;
	flex-wrap: wrap;

	@include tablet {
		width: 66.66%; }

	@include mobile {
		width: 100%; } }

.news__item {
	width: 100%;
	padding-left: 12px;
	padding-right: 12px;
	margin-bottom: 24px;
	position: relative;

	&:hover {

		.news__img {

			img {
				transform: scale(1.1); } } }

	&:not(.news--first) {
		width: 50%;

		@include mobile {
			width: 100%; } } }

.news__item-inner {
	background: #fff;
	height: 100%; }

.news__img {
	overflow: hidden;

	@include mobile {
		&.news--first {
			padding-top: 100%;
			position: relative;

			img {
				top: 0;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				width: auto;
				height: 100%;
				position: absolute;
				max-width: none; } } }

	img {
		display: block;
		width: 100%;
		transition-duration: $trds; } }

.news__info {
	padding: 24px; }

.news__title {
	font-weight: 500;
	font-size: 24px;
	line-height: 120%;
	color: #121216;
	margin-bottom: 8px;
	text-decoration: none;
	display: block;

	@include tablet {
		font-size: 20px; }

	&.news--first {
		margin-bottom: 16px; } }

.news__intro {

	&.news--first {
		margin-bottom: 16px;

		@include tablet {
			display: none; } } }

.news__desc {
	color: #999999;
	font-weight: 400;
	margin-bottom: 100px;

	@include tablet {
		display: none; } }

.news__date {
	position: absolute;
	top: 12px;
	left: 24px;

	&.news--first {
		position: relative;
		left: auto;
		top: auto;

		@include tablet {
			position: absolute;
			top: 12px;
			left: 24px; } } }

.news__one {
	display: flex;
	margin-bottom: 100px;
	flex-wrap: wrap; }

.news-one__image {
	width: 500px;

	@include tablet {
		width: 435px; }

	@include mobile {
		width: 50%;
		padding-right: 12px; }

	@include minMobile {
		width: 100%;
		padding-right: 0;
		margin-bottom: 24px; } }

.news-one__img {
	display: block;
	width: 100%; }

.news-one__info {
	flex: 1 0;
	margin-left: 112px;

	@include tablet {
		margin-left: 24px; }

	@include mobile {
		width: 50%;
		flex: none;
		padding-left: 12px;
		margin-left: 0; }

	@include minMobile {
		width: 100%;
		padding-left: 0; }

	h1 {
		margin-bottom: 20px; } }

