.contacts__wrap {}

.contacts__info {
	width: 306px;
	float: left;

	@include tablet {
		width: 256px; }

	@include mobile {
		width: 100%;
		float: none; } }

.contacts__city {
	display: flex;
	flex-wrap: wrap; }

.contacts-city__item {

	@include mobile {
		width: 100%;
		margin-bottom: 16px; }

	@include minMobile {
		margin-bottom: 0; } }

.contacts-city__title {
	font-weight: 500;
	font-size: 24px;
	line-height: 120%;
	margin-bottom: 12px;
	display: flex;
	align-items: center;
	cursor: pointer;

	&.open {
		color: $orange;

		svg {
			fill: $orange;
			transform: rotate(180deg); } }

	&.city--open {

		& + .contacts-city__info {
			height: 0;
			overflow: hidden;
			opacity: 0; }

		&.open {

			& + .contacts-city__info {
				height: auto;
				opacity: 1; } } }

	svg {
		width: 18px;
		height: 14px;
		fill: #3E3F42;
		margin-left: 14px;
		transition-duration: $trds; } }

.contacts-city__name {
	margin-bottom: 12px;
	display: flex;
	align-items: center;
	font-weight: 500;
	font-size: 24px;
	line-height: 120%;

	svg {
		width: 24px;
		height: 24px;
		margin-right: 10px; }

	@include minMobile {
		font-weight: 500;
		font-size: 18px; } }

.contacts-city__info {
	transition-duration: $trds;
	display: flex;
	flex-direction: column;

	@include mobile {
		flex-direction: row; }

	@include minMobile; }

.contacts-city__col {
	margin-bottom: 36px;

	@include mobile {
		padding-right: 0;

		& + .contacts-city__col {
			margin-left: 50px; } }

	@include minMobile {
		margin-bottom: 32px;

		& + .contacts-city__col {
			margin-left: 20px; } } }

.contacts__map-wrap {
	margin-left: 306px;

	@include tablet {
		margin-left: 256px; }

	@include mobile {
		margin-left: 0; } }

.contacts__map {
	width: 100%; }

#contacts__map {
	width: 100%;
	height: 480px;

	@include tablet {
		height: 360px; }

	@include mobile;

	@include minMobile {
		height: 320px; } }

.ymaps-2-1-79-balloon {
	box-shadow: none !important;

	ymaps {
		font-family: $base-font-family !important; } }

.ymaps-2-1-79-balloon__layout {
	border-radius: 12px !important;
	border: none !important; }

.ymaps-2-1-79-balloon__close {
	display: none !important; }

.map__title {
	color: $orange;
	font-weight: 500;
	font-size: 16px;
	line-height: 120%;
	color: #E5372E;
	margin-bottom: 4px; }

.map__adres {
	font-size: 12px;
	line-height: 120%;
	color: #3E3F42;
	margin-bottom: 4px; }

.map__phone {
	font-size: 14px;
	line-height: 120%;
	color: #3E3F42; }

.contacts__map {
	margin-bottom: 100px;

	@include tablet {
		margin-bottom: 40px; } }
