@import "utils/libs";
@import "utils/vars";
@import "utils/mixins";
@import "utils/fonts";

html {
	height: 100%;
	background: #F0F0F0; }

body {
	font: 400 16px/120% $base-font-family;
	color: #3E3F42;
	height: 100%;
	scroll-behavior: smooth;
	text-rendering: optimizeSpeed;
	min-width: 300px;
	background: transparent; }

*,
*::before,
*::after {
	box-sizing: border-box; }

ul,
ol {
  list-style: none;
  padding: 0; }

body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
	margin: 0; }

a:not([class]) {
	text-decoration-skip-ink: auto; }

img {
  max-width: 100%;
  display: block; }

input,
button,
textarea,
select {
	font: inherit;
	width: 100%; }

/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important; } }

audio,
canvas,
iframe,
img,
svg,
video,
textarea {
	vertical-align: middle; }

textarea {
	resize: none; }

section {
	position: relative; }

input,
select,
button {
	outline: none; }

.d-f {
	display: flex; }

.a-c {
	align-items: center; }

.j-c {
	justify-content: center; }

.t-c-2 {
	column-width: calc(50% - 24px);
	column-count: 2;
	column-gap: 24px; }

a {
	color: $orange;

	&:hover {
		text-decoration: none; } }

.button {
	display: flex;
	width: 150px;
	height: 34px;
	align-items: center;
	justify-content: center;
	border-radius: 17px;
	border: 1px solid $orange;
	text-decoration: none;
	font-size: 14px;
	cursor: pointer;
	transition-duration: $trds;
	&:hover {
		background: $orange;
		color: #fff; }

	&.button--orange {
		background: $orange;
		color: #fff;
		&:hover {
			background: transparent;
			color: $black; } }

	&.button--white {
		background: $white;
		color: $black;
		border-color: $white;
		&:hover {
			background: transparent;
			color: $white; } } }

.wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100%; }

.content {
	flex: 1 0 auto;
	padding: 60px 0 24px;
	background: transparent;
	line-height: normal;

	h1 {
		font-weight: 500;
		font-size: 32px;
		line-height: 120%;
		color: #121216;
		margin-bottom: 36px;

		@include tablet {
			font-size: 24px;
			margin-bottom: 20px; } }

	h2 {
		font-weight: 500;
		font-size: 32px;
		line-height: 120%;
		color: #121216;
		margin-bottom: 36px; }

	.h1 {
		font-weight: 500;
		font-size: 32px;
		line-height: 120%;
		color: #121216;
		margin-bottom: 36px;

		@include tablet {
			font-size: 24px;
			margin-bottom: 20px; } }

	.h2 {
		font-weight: 500;
		font-size: 32px;
		line-height: 120%;
		color: #121216;
		margin-bottom: 36px; } }

.container {
	margin: 0 auto;
	max-width: $content-size;
	width: 100%;
	padding: 0 15px; }

.content {
	.page-inner {
		display: flex;
		h1 {
			font-weight: 700;
			font-size: 20px;
			line-height: 24px;
			color: #1C1D1F;
			margin-bottom: 20px; } }

	p {
		margin-bottom: 15px;
		&:last-child {
			margin-bottom: 0; } }

	ul {
		li {
			padding-left: 15px;
			position: relative;
			margin-bottom: 13px;

			&:last-child {
				margin-bottom: 0; }

			&:before {
				content: '';
				background: $orange;
				width: 6px;
				height: 6px;
				border-radius: 50%;
				display: block;
				position: absolute;
				left: 0;
				top: 5px; } } } }


@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0,70px,0);
        transform: translate3d(0,70px,0); }

    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0); } }

@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0,70px,0);
        transform: translate3d(0,70px,0); }

    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0); } }

form.form {
	position: relative;

	.form__success {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: -1px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		transition-duration: $trds;
		visibility: hidden;
		opacity: 0;

		&.open {
			visibility: visible;
			opacity: 1; }

		svg {
			width: 80px;
			height: 80px;
			fill: #999999;
			margin-bottom: 22px;

			path {
				fill: #999999; } }

		.form-success__title {
			font-weight: 500;
			font-size: 24px;
			line-height: 120%;
			color: #121216;
			margin-bottom: 22px; }

		.form-success__desc {
			text-align: center; } }

	.form__label {
		margin-bottom: 16px;
		display: block; }

	.form-label__title {
		font-size: 16px;
		line-height: 120%;
		color: #999999;
		padding-left: 15px;
		margin-bottom: 4px; }

	.form-label__input-wrap {
		position: relative; }

	.form-label__req {
		position: absolute;
		right: 15px;
		top: 5px;
		color: #999999; }

	.form-label__input {
		height: 50px;
		width: 100%;
		border: 1px solid #fff;
		border-radius: 25px;
		padding-left: 12px;
		padding-right: 12px;

		&.error {
			background: #FFF5F5;
			border: 1px solid #E5372E;

			& + .form-label__req {
				color: #E5372E; } } }

	.form-label__select {
		height: 50px;
		width: 100%;
		border: 1px solid #fff;
		border-radius: 25px;
		padding-left: 12px;
		padding-right: 12px;

		& + .select2 {

			.select2-selection--single {
				height: 50px;
				border: 1px solid #fff;
				border-radius: 25px;

				.select2-selection__rendered {
					height: 50px;
					width: 100%;
					padding-left: 12px;
					padding-right: 12px;
					display: flex;
					align-items: center; }

				.select2-selection__arrow {
					display: none; } } } }


	.button {
		width: 210px;
		height: 36px;
		font-size: 16px; } }

.select2-container--default {

	.select2-search--dropdown {
		padding: 0; }

	.select2-dropdown {
		padding: 0;
		border: none;
		border-radius: 25px !important;

		&.select2-dropdown--below {
			margin-top: -50px; }

		&.select2-dropdown--above {
			margin-top: 50px;
			display: flex;
			flex-direction: column;

			.select2-search {
				order: 2; }

			.select2-search__field {
				order: 2;
				box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.16); } }

		.select2-search__field {
			border: none;
			border-radius: 25px;
			height: 50px;
			width: 100%;
			padding-left: 12px;
			padding-right: 12px;
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
			border: none !important; } } }

.select2-results {
	padding-top: 20px;
	padding-bottom: 20px;

	.select2-results__option--selected {
		background: #ededed !important; }

	.select2-results__option {
		padding: 12px;
		color: #999999 !important;
		font-size: 16px;
		line-height: 120%;

		&.select2-results__option--highlighted {
			background: #E5372E !important;
			color: #fff !important; } } }

.popup {
	display: none;

	&.open {
		display: block; } }

@media screen and (min-width: 992px) {

	#popup__form--header {
		padding: 24px 36px;
		position: absolute;
		left: 0;
		transform: translate(0);
		top: 64px;
		width: 320px;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);

		.form-label__input {
			height: 40px; } } }

.popup {
	position: fixed;
	width: 100%;
	max-width: 400px;
	padding: 35px 76px;
	background: #fff;
	border-radius: 25px;
	z-index: 101;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);

	.form__success {
		background: #fff; }

	.popup__close {
		position: absolute;
		right: 14px;
		top: 14px;

		svg {
			stroke: #999999;
			width: 24px;
			height: 24px; } }

	form.form {

		.form-label__input {
			border: 1px solid #999999;

			&.error {
				background: #FFF5F5;
				border: 1px solid #E5372E;

				& + .form-label__req {
					color: #E5372E; } } }


		.button {
			margin-left: auto;
			margin-right: auto; } } }

/*.popup__close
	position: absolute
	top: 15px
	right: 15px

	&:hover
		svg
			fill: $orange

	svg
		fill: #8E8E8E
		width: 12px
		height: 12px*/

.popup__wrap {
	padding: 23px;
	background: #fff;
	width: 100%;
	max-width: 650px;

	.form__label {
		margin-bottom: 12px; }

	.form__input {
		background: #FFFFFF;
		border: 1px solid rgba(0, 0, 0, 0.1);
		border-radius: 4px;
		height: 46px;
		font-family: $base-font-family;
		font-size: 15px;
		padding-left: 17px;
		padding-right: 17px;
		width: 100%; }

	.form__button {
		width: 100%;
		height: 46px;
		color: #fff;
		margin-bottom: 0; }

	.form__title {
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
		color: #1C1D1F;
		margin-bottom: 25px;
		text-align: center; }

	.form__subtitle {
		font-size: 13px;
		line-height: 16px;
		color: #8E8E8E;
		margin-bottom: 12px; } }

.overlay {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(47, 47, 47, 0.25);
	display: none; }

.nav__mobile {
	position: fixed;
	z-index: 101;
	left: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	max-width: 360px;
	background: #3E3F42;
	padding: 84px 24px;
	color: $white;
	transform: translateX(-100%);
	transition-duration: $trds;
	overflow-y: auto;

	&.open {
		transform: translateX(0%); }

	.nav-mobile__close {
		width: 24px;
		height: 24px;

		svg {
			width: 24px;
			height: 24px;
			stroke: $white;
			position: absolute;
			top: 20px;
			right: 20px; } }

	.nav-mobile__list {
		list-style: none;
		padding: 0;
		margin: 0;
		margin-bottom: 40px; }

	.nav-mobile__item {

		& + .nav-mobile__item {
			margin-top: 20px; } }

	.nav-mobile__link {
		font-weight: 500;
		font-size: 24px;
		line-height: 120%;
		color: $white;
		text-decoration: none; }

	.nav-mobile__logo {
		margin-bottom: 20px;

		.nav-mobile__link {

			svg {
				width: 155px;
				height: 40px;
				fill: $white; } } }

	.nav-mobile__button {
		margin-bottom: 20px;

		&:hover {
			color: $white; } }

	.nav-mobile__contacts {
		display: flex;
		flex-direction: column; }

	.nav-mobile__phone {
		color: $white;
		text-decoration: none;
		margin-bottom: 20px; } }

.breacrumbs {
	font-size: 14px;
	line-height: 120%;
	margin-bottom: 20px;

	.breacrumbs__list {
		display: flex;
		flex-wrap: wrap; }

	.breacrumbs__item {

		&::after {
			content: '/';
			margin-left: 4px;
			margin-right: 4px; }

		&:last-child {

			&::after {
				display: none; } } }

	.breacrumbs__link {
		color: #3E3F42;
		text-decoration: none; } }


.slick-slider {

	&.slick-dotted.gallery__single {
		margin-bottom: 60px; }

	.slick-next:before, .slick-prev:before {
		display: none; }

	.slick-slide {

		img {
			width: 100%; } }

	.slick-prev, .slick-next {
		background-repeat: no-repeat;
		background-position: 50% 50%;
		z-index: 5; }

	.slick-prev {
		width: 52px;
		height: 52px;
		background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAxOCAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2IDI5TDEuODU3ODYgMTQuODU3OUwxNi44NTA1IDEuNjIwNzEiIHN0cm9rZT0iIzNFM0Y0MiIgc3Ryb2tlLXdpZHRoPSIyIj48L3BhdGg+Cjwvc3ZnPg==");
		left: 0; }

	.slick-next {
		width: 52px;
		height: 52px;
		right: 0;
		background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAxOCAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIgMUwxNi4xNDIxIDE1LjE0MjFMMS4xNDk1NSAyOC4zNzkzIiBzdHJva2U9IiMzRTNGNDIiIHN0cm9rZS13aWR0aD0iMiI+PC9wYXRoPgo8L3N2Zz4="); }

	.slick-prev:focus, .slick-prev:hover {
		background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAxOCAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2IDI5TDEuODU3ODYgMTQuODU3OUwxNi44NTA1IDEuNjIwNzEiIHN0cm9rZT0iIzNFM0Y0MiIgc3Ryb2tlLXdpZHRoPSIyIj48L3BhdGg+Cjwvc3ZnPg==");
		background-repeat: no-repeat;
		background-position: 50% 50%; }

	.slick-next:focus, .slick-next:hover {
		background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAxOCAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIgMUwxNi4xNDIxIDE1LjE0MjFMMS4xNDk1NSAyOC4zNzkzIiBzdHJva2U9IiMzRTNGNDIiIHN0cm9rZS13aWR0aD0iMiI+PC9wYXRoPgo8L3N2Zz4=");
		background-repeat: no-repeat;
		background-position: 50% 50%; }

	.slick-dots {
		display: flex;
		justify-content: center;
		bottom: -40px;

		li {
			padding: 0;
			margin: 0;
			width: 16px;
			height: 16px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 5px;

			&.slick-active {

				button {
					background: $orange;
					width: 16px;
					height: 16px; } }

			button {
				background: #DBDBDB;
				width: 10px;
				height: 10px;
				border-radius: 50%;
				padding: 0;
				transition-duration: $trds; }

			button:before {
				display: none; } }

		li:before {
			display: none; } } }

.related-carousel {
	margin-left: -12px;
	margin-right: -12px;

	@include minMobile {
		margin-left: -10px;
		margin-right: -10px; }

	.slick-track {
		display: flex; }

	.related__item {

		.category__title {
			height: auto;
			overflow: visible; } }

	.related__item.category__item {
		width: 282px;
		padding-left: 12px;
		padding-right: 12px;

		@include minMobile {
			padding-left: 10px;
			padding-right: 10px; }

		&.slick-slide {
			height: auto;
			float: none; }

		@include minMobile {
			width: 156px !important; }

		.category__intro {

			@include mobile {
				display: none; } }

		.category__value {

			@include mobile {
				display: none; } }

		.category__img {

			@include mobile {
				margin: 10px;
				width: calc(100% - 20px); } }

		.category__title {

			@include minMobile {
				padding-left: 8px;
				padding-right: 8px; } } }

	.related__item-inner {
		background: #fff; } }

.header__search {
	position: relative; }

.header-search__form {

	form {
		position: absolute;
		width: 0px;
		right: 0px;
		top: 50%;
		margin-top: -20px;
		opacity: 0;
		transition-duration: $trds;
		overflow: hidden;

		&.open {
			width: 360px;
			opacity: 1;

			@include minMobile {
				width: 290px; } } }

	button {
		display: none; }

	input {
		height: 40px;
		width: 100%;
		border: 1px solid #999999;
		border-radius: 25px;
		padding-left: 45px;
		padding-right: 12px;
		font-family: $base-font-family;
		background-image: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.375 16.625C7.50901 16.625 4.375 13.491 4.375 9.625C4.375 5.75901 7.50901 2.625 11.375 2.625C15.241 2.625 18.375 5.75901 18.375 9.625C18.375 13.491 15.241 16.625 11.375 16.625Z' stroke='%23121216' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M2.62539 18.375L6.43164 14.5687' stroke='%23121216' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
		background-position: 15px 50%;
		background-repeat: no-repeat; } }

//Модули
@import "modules/modules";
