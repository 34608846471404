div.page-index {

	.catalog__row {
		margin-left: -12px;
		margin-right: -12px;
		display: flex;

		&:last-child {

			.catalog__item {

				&:last-child {
					margin-bottom: 0; } } } }

	.catalog__item {
		padding-left: 12px;
		padding-right: 12px;
		margin-bottom: 24px;


		.button {
			padding-top: 6px;
			padding-bottom: 10px; }

		&.w2 {
			width: 50%; }

		&.cat_1 {

			.catalog__item-desc {
				top: 40px;
				left: 60px;

				@include laptop {
					left: 40px; } } }

		&.cat_2 {

			.catalog__item-desc {
				bottom: 60px;
				right: 50px;

				@include laptop {
					right: 40px;
					bottom: 40px; } } }

		&.cat_3 {

			.catalog__item-desc {
				top: 30px;
				left: 40px; } }

		&.cat_4 {

			.catalog__item-desc {
				top: 30px;
				left: 40px; } }

		&.cat_5 {

			.catalog__item-desc {
				bottom: 60px;
				right: 50px;

				@include laptop {
					right: 40px;
					bottom: 40px; } } } }

	.catalog__item-inner {
		position: relative; }

	.catalog__item-img {
		display: block;
		width: 100%; }

	.catalog__item-desc {
		position: absolute;
		display: flex;
		flex-direction: column;
		z-index: 2; }

	.catalog__item-title {
		font-weight: 500;
		font-size: 32px;
		line-height: 120%;
		color: #FFFFFF;
		margin-bottom: 24px;
		text-decoration: none; }

	.catalog__item-link {
		color: #E5372E;
		font-size: 16px;
		line-height: 120%;
		text-decoration: none;

		&:hover {

			svg {
				margin-left: 15px; } }

		svg {
			width: 21px;
			height: 16px;
			stroke: #E5372E;
			margin-left: 9px;
			transition-duration: $trds; } } }
