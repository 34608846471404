.partners__steps {
	display: flex;
	justify-content: flex-start;
	margin-left: -12px;
	margin-right: -12px;
	margin-bottom: 12px;
	flex-wrap: wrap; }

.partners__item {
	padding-left: 12px;
	padding-right: 12px;
	width: 25%;
	margin-bottom: 24px;
	margin-top: 12px; }

.partner__item {
	border: 1px solid #3E3F42;
	height: 100%;
	padding: 24px;
	padding-top: 115px;
	position: relative; }

.partners__number {
	font-weight: 400;
	font-size: 64px;
	line-height: 100%;
	position: absolute;
	top: -22px;
	left: 25px;
	background: #F0F0F0;
	padding-left: 12px;
	padding-right: 12px; }
