section.contacts-map {
	background: #DBDBDB;
	padding-top: 48px;
	padding-bottom: 54px;

	.form__success {
		background: #DBDBDB; }

	.container {
		display: flex; }

	.contacts-map__map {
		width: 50%;
		padding-right: 22px; }

	.contacts-map__info {
		width: 50%;
		padding-left: 22px; }

	#map {
		width: 100%;
		height: 598px;

		@include laptop {
			height: 498px; }

		@include mobile {
			height: 439px; }

		@include minMobile {
			height: 279px; } }

	.contacts-map-info__title {
		font-weight: 500;
		font-size: 32px;
		line-height: 120%;
		margin-bottom: 24px; }

	.contacts-map-info__info-phone {
		font-size: 16px;
		line-height: 120%;
		color: #3E3F42;
		margin-bottom: 8px; }

	.contacts-map-info__info-email {
		font-size: 16px;
		line-height: 120%;
		color: #3E3F42;
		margin-bottom: 16px; }

	.contacts-map-info__desc {
		font-size: 16px;
		line-height: 100%;
		color: #3E3F42;
		margin-top: 16px;

		svg {
			width: 12px;
			height: 12px;
			stroke: $orange; } }

	.contacts-map__form {
		max-width: 420px;

		.form-label__input-wrap {} }

	.ymaps-2-1-79-ground-pane {
		filter: grayscale(100%); } }
