@mixin size($a,$b: $a) {
  width: $a;
  height: $b;
}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width:  $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
		background: $foreground-color;
		border-radius: 4px;
		cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

@mixin laptop {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 992px) {
    @content;
  }
}

@mixin upTablet {
  @media (min-width: 991px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 760px) {
    @content;
  }
}

@mixin upMobile {
  @media (min-width: 759px) {
    @content;
  }
}

@mixin minMobile {
  @media (max-width: 576px) {
    @content;
  }
}
