
@media screen and (max-width: 992px) {
	a.open__nav {
		display: flex; }

	.hide__mobile {
		display: none; }

	header {
		height: 68px;

		.header__phone {
			display: none; }

		.header-logo__link {

			svg {
				width: 116px;
				height: 32px; } }

		.header__search {
			width: auto;

			.button {
				display: none; } } }

	.nav {
		display: none; }

	.content {
		padding-top: 40px;

		h1 {
			margin-bottom: 24px; } }

	.category__filter {

		.category-filter__wrap {
			justify-content: flex-start; }

		label.category-filter__label {
			margin-right: 20px;
			margin-left: 0; } }

	section.category {

		.category__item {
			width: 25%; }

		.category__title {
			font-size: 16px;
			font-weight: 500; } }

	section.contacts-map {

		.container {
			flex-direction: column; }

		.contacts-map__map {
			padding-right: 0;
			width: 100%;
			order: 2;
			margin-top: 24px; }

		.contacts-map__info {
			padding-left: 0;
			width: 100%;
			max-width: 420px;
			margin-left: auto;
			margin-right: auto; } }

	.category__item {

		.item__gallery {
			width: 320px;

			.item__yt {
				display: block; } }

		.item__info {

			.item__yt {
				display: none; } } }

	.t-c-2 {
		column-count: 1;
		column-gap: 0;
		column-width: 100%; }

	.partners__item {
		width: 33.33%; }

	.about__wrap {
		background: url(../images/content/about-bg.jpg) no-repeat 50% 110px;
		background-size: 125% auto; }

	.about-row__first {
		margin-bottom: 58%; }

	.about-row__first {

		.about__desc {
			max-width: 460px;
			width: 100%; } }

	html.page-about {

		.about-row__first {

			.about__desc {
				grid-template-columns: repeat(1, 1fr); } } } }


@media screen and (max-width: 767px) {

	section.category {

		.category__item {
			width: 50%; } }

	.category__filter {

		.category-filter__wrap {
			margin-bottom: 24px; }

		label.category-filter__label {
			margin: 0;

			input:checked {

				& + .category-filter__value {
					display: flex;
					margin-top: 24px; } } }

		.category-filter__value {
			display: none; } }

	.content {
		padding-top: 24px; }

	div.page-index {

		.catalog__row {
			flex-wrap: wrap; }

		.catalog__item {

			&.w2 {
				width: 100%; }

			&.cat_1 {

				.catalog__item-desc {
					left: 24px;
					top: 24px; } }

			&.cat_2 {

				.catalog__item-desc {
					right: 24px;
					bottom: 24px; } }

			&.cat_3 {

				.catalog__item-desc {
					left: 24px;
					top: 24px; } }

			&.cat_4 {

				.catalog__item-desc {
					left: 24px;
					top: 24px; } }

			&.cat_5 {

				.catalog__item-desc {
					right: 24px;
					bottom: 24px; } } }


		.catalog__item-title {
			font-size: 24px; } }

	div.page-content {

		&.page-404 {

			.error__button {
				width: 100%;
				display: flex;
				justify-content: center;

				.button {
					width: 100%;
					max-width: 150px;

					& + .button {
						margin-left: 20px; } } } } }

	footer {

		.footer__nav {

			&.nav--catalog {
				display: none; } } }

	.category__item {

		.item__wrap {
			flex-direction: column; }

		.item__gallery {
			width: 100%;
			max-width: 100%; }

		.item__info {
			width: 100%;
			max-width: 100%; } }

	.partners__item {
		width: 50%; }

	.partner__item {
		padding-top: 72px; }

	.about__desc {
		margin-bottom: 32px; }

	.about__products {
		margin-bottom: 32px; }

	html.page-about {

		.about__desc {
			grid-template-columns: repeat(1, 1fr); } }

	.about__wrap {
		background: url(../images/content/about-bg.jpg) no-repeat 75% 26%;
		background-size: 160% auto;
		padding-top: 24px; }

	.about-row__first {
		margin-bottom: 95%; }

	html.page-about {

		.about-row__first {

			.about__desc {
				grid-template-columns: repeat(1, 1fr); } } } }

@media screen and (max-width: 576px) {
	section.category {

		&.category--item {

			.category__item {
				width: 100%; } } }

	footer {
		padding-bottom: 108px;

		.container {
			flex-direction: column; }

		.footer__logo {
			margin-right: 0;
			flex-wrap: wrap;
			flex-direction: row;
			justify-content: space-between;

			.button {
				width: calc(50% - 10px); } }

		.footer-logo__link {
			width: 100%;
			margin-bottom: 20px;

			svg {
				width: 155px;
				height: 40px; } }

		.footer__contacts {
			margin-top: 0; }

		.footer__copy {
			margin-top: 20px;
			margin-bottom: 20px; }

		.footer__nav {
			width: 100%; } }

	.about__marketing-carousel {
		_margin: 0;

		.slick-track {
			_display: flex;
			_flex-direction: column; } }

	.about__marketing-item {
		_width: 100% !important;
		_padding: 0;
		_margin-bottom: 24px; }

	.about__products-item {
		padding: 0; }

	.about__products-carousel {

			.slick-prev, .slick-next {
				filter: invert(1); } }

	.partners__item {
		width: 100%; }

	.partner__item {
		padding-top: 54px; } }
