.about__map {
	width: 100%;
	margin-bottom: 43px; }

.about__desc {
	margin-bottom: 60px; }

.about__products {
	margin-bottom: 60px; }

.about__products-carousel {}

.about__products-item {
	padding: 0 12px;

	img {} }

.about__marketing-carousel {
	margin: 0 -12px;
	margin-bottom: 90px; }

.about__marketing-item {
	padding: 0 12px;
	position: relative;

	&:hover {

		img {
			transform: scale(1.1); } }

	& > a {
		overflow: hidden;
		display: block;
		margin-bottom: 12px; } }

.about__marketing-soon {
	position: absolute;
	top: 16px;
	right: 28px;
	font-size: 24px;
	color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
	background-image: url("data:image/svg+xml,%3Csvg width='90' height='94' viewBox='0 0 90 94' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M37.6736 3.88653C41.6296 -0.371973 48.3704 -0.371975 52.3264 3.88653L53.4874 5.13622C55.8435 7.67241 59.3436 8.80969 62.7405 8.14273L64.4143 7.81409C70.1179 6.69421 75.5712 10.6563 76.2687 16.4268L76.4733 18.1202C76.8887 21.5569 79.0519 24.5344 82.1921 25.9914L83.7394 26.7094C89.0119 29.1558 91.0949 35.5667 88.2673 40.6451L87.4376 42.1354C85.7536 45.1598 85.7536 48.8402 87.4376 51.8646L88.2673 53.3549C91.0949 58.4333 89.0119 64.8442 83.7394 67.2906L82.1921 68.0086C79.0519 69.4656 76.8887 72.4431 76.4734 75.8798L76.2687 77.5732C75.5712 83.3437 70.1179 87.3058 64.4143 86.1859L62.7405 85.8573C59.3436 85.1903 55.8435 86.3276 53.4874 88.8638L52.3264 90.1135C48.3704 94.372 41.6296 94.372 37.6736 90.1135L36.5126 88.8638C34.1565 86.3276 30.6564 85.1903 27.2595 85.8573L25.5858 86.1859C19.8821 87.3058 14.4288 83.3437 13.7313 77.5732L13.5267 75.8798C13.1113 72.4431 10.9481 69.4656 7.80792 68.0086L6.26063 67.2906C0.988068 64.8442 -1.09493 58.4333 1.73265 53.3549L2.56243 51.8646C4.24643 48.8402 4.24643 45.1598 2.56243 42.1354L1.73266 40.6451C-1.09493 35.5667 0.988072 29.1558 6.26064 26.7094L7.80792 25.9914C10.9481 24.5344 13.1113 21.5569 13.5267 18.1202L13.7313 16.4268C14.4288 10.6563 19.8821 6.69422 25.5857 7.81409L27.2595 8.14273C30.6564 8.80969 34.1565 7.67241 36.5126 5.13622L37.6736 3.88653Z' fill='%23E5372E'/%3E%3C/svg%3E");
	width: 102px;
	height: 102px;
	background-repeat: no-repeat;
	background-position: 50% 50%; }

.about__marketing-title {

	svg {
		width: 24px;
		height: 24px;
		stroke: #3E3F42;
		margin-left: 16px; } }

.about__marketing-link {
	color: #3E3F42;
	text-decoration: none; }

.about__marketing-img {
	display: block;
	width: 100%;
	transition-duration: $trds; }

.about__marketing-item {
	width: 248px; }

.about__wrap {
	background: url(../images/content/about-bg.jpg) no-repeat 50% 50px;
	background-size: 100% auto;
	padding-top: 60px;
	padding-left: 0;
	padding-right: 0;

	@include tablet {
		padding-top: 52px; }

	h1 {
		font-weight: 700; } }

html.page-about {
	background: #dedede;

	.content {
		padding-top: 0; }

	.about__desc {
		display: grid;
		gap: 30px;
		grid-template-columns: repeat(2, 1fr); } }

.about-desc__title {
	color: #E5372E;
	font-size: 20px;
	margin-bottom: 20px; }

.about-row__first {
	margin-bottom: 42%; }
