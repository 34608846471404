.category__item {

	.item__wrap {
		display: flex;
		justify-content: space-between; }

	.item__gallery {
		width: 100%;
		max-width: 500px;
		margin-right: 24px;
		position: relative;

		.item__yt {
			position: absolute;
			left: 20px;
			top: 16px;
			z-index: 5;
			display: none;

			svg {
				width: 40px;
				height: 28px; } } }

	.item__info {
		width: 100%;
		max-width: 588px;

		h1 {
			margin-bottom: 8px; }

		.item__subtitle {
			font-weight: 500;
			font-size: 24px;
			margin-bottom: 8px; }

		.item__title {
			border-bottom: 1px solid #121216;
			width: 128px;
			padding-bottom: 4px;
			margin-bottom: 8px; }

		.item__content {
			margin-bottom: 32px; }

		.item__spec {
			margin-bottom: 32px; }

		.item__table {
			margin-bottom: 32px; }

		.item__info {
			margin-bottom: 32px;
			display: flex; }

		.item__pdf {
			background: #FFFFFF;
			border: 1px solid #E5372E;
			display: flex;
			height: 36px;
			width: 80px;
			align-items: center;
			justify-content: center;
			border-radius: 18px;
			text-decoration: none;
			color: #121216;
			margin-left: 24px; }

		.item__yt {
			margin-left: 24px;

			svg {
				width: 40px;
				height: 28px; } }

		.item__table-one {
			margin-bottom: 8px;

			thead {
				display: none; }

			td {

				&:last-child {

					&::after {
						display: none; } }

				&::after {
					content: '—';
					margin: 0 5px; } } }

		.item__table {

			table {
				width: 100%;
				border-collapse: collapse;

				tr {
					text-align: center;

					th {
						border: 1px solid #DBDBDB;
						font-weight: 400;
						padding: 2px 15px; }

					td {
						border: 1px solid #DBDBDB;
						font-weight: 400;
						padding: 8px 15px; } } } }

		.table__more {
			display: flex;
			width: 100%;
			justify-content: center;
			margin-top: 16px;
			text-decoration: none;
			color: #3E3F42;
			align-items: center;

			&.open {

				&::before {
					content: 'скрыть'; }

				&::after {
					background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxNiAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTggMUw4LjU5MjAxIDAuNTM5NTQ0QzguNDQ5OTIgMC4zNTY4NTQgOC4yMzE0NCAwLjI1IDggMC4yNUM3Ljc2ODU2IDAuMjUgNy41NTAwOCAwLjM1Njg1NCA3LjQwNzk5IDAuNTM5NTQ0TDggMVpNMC40MDc5ODYgOS41Mzk1NEMwLjE1MzY4MyA5Ljg2NjUgMC4yMTI1ODQgMTAuMzM3NyAwLjUzOTU0NSAxMC41OTJDMC44NjY1MDUgMTAuODQ2MyAxLjMzNzcxIDEwLjc4NzQgMS41OTIwMSAxMC40NjA1TDAuNDA3OTg2IDkuNTM5NTRaTTE0LjQwOCAxMC40NjA1QzE0LjY2MjMgMTAuNzg3NCAxNS4xMzM1IDEwLjg0NjMgMTUuNDYwNSAxMC41OTJDMTUuNzg3NCAxMC4zMzc3IDE1Ljg0NjMgOS44NjY1IDE1LjU5MiA5LjUzOTU0TDE0LjQwOCAxMC40NjA1Wk03LjQwNzk5IDAuNTM5NTQ0TDAuNDA3OTg2IDkuNTM5NTRMMS41OTIwMSAxMC40NjA1TDguNTkyMDEgMS40NjA0Nkw3LjQwNzk5IDAuNTM5NTQ0Wk03LjQwNzk5IDEuNDYwNDZMMTQuNDA4IDEwLjQ2MDVMMTUuNTkyIDkuNTM5NTRMOC41OTIwMSAwLjUzOTU0NEw3LjQwNzk5IDEuNDYwNDZaIiBmaWxsPSIjM0UzRjQyIi8+Cjwvc3ZnPgo="); } }

			&::before {
				content: 'еще';
				margin-right: 5px; }

			&::after {
				content: '';
				display: block;
				width: 14px;
				height: 9px;
				background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxNiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTggMTAuNUw3LjQwNzk5IDEwLjk2MDVDNy41NTAwOCAxMS4xNDMxIDcuNzY4NTYgMTEuMjUgOCAxMS4yNUM4LjIzMTQ0IDExLjI1IDguNDQ5OTIgMTEuMTQzMSA4LjU5MjAxIDEwLjk2MDVMOCAxMC41Wk0xNS41OTIgMS45NjA0NkMxNS44NDYzIDEuNjMzNSAxNS43ODc0IDEuMTYyMjkgMTUuNDYwNSAwLjkwNzk4NkMxNS4xMzM1IDAuNjUzNjgzIDE0LjY2MjMgMC43MTI1ODQgMTQuNDA4IDEuMDM5NTRMMTUuNTkyIDEuOTYwNDZaTTEuNTkyMDEgMS4wMzk1NEMxLjMzNzcxIDAuNzEyNTg0IDAuODY2NTA1IDAuNjUzNjgzIDAuNTM5NTQ0IDAuOTA3OTg2QzAuMjEyNTg0IDEuMTYyMjkgMC4xNTM2ODQgMS42MzM1IDAuNDA3OTg2IDEuOTYwNDZMMS41OTIwMSAxLjAzOTU0Wk04LjU5MjAxIDEwLjk2MDVMMTUuNTkyIDEuOTYwNDZMMTQuNDA4IDEuMDM5NTRMNy40MDc5OSAxMC4wMzk1TDguNTkyMDEgMTAuOTYwNVpNOC41OTIwMSAxMC4wMzk1TDEuNTkyMDEgMS4wMzk1NEwwLjQwNzk4NiAxLjk2MDQ2TDcuNDA3OTkgMTAuOTYwNUw4LjU5MjAxIDEwLjAzOTVaIiBmaWxsPSIjM0UzRjQyIi8+Cjwvc3ZnPgo=");
				background-size: 14px 9px; } } } }

